import React, { ComponentType, useContext } from 'react';
import { PageMetadataContext } from '../../../templates/PageMetaDataContext';
import {
    countriesList,
    defaultDomainCountryCodes,
} from '../../../global/countries';
//@ts-ignore
import * as styles from './CtaLink.module.css';

interface Props {
    href?: string;
    target?: string;
    color?: string;
    hoverBorderColor?: string;
    hoverFontColor?: string;
    hoverBgColor?: string;
    visualStyle?: 'outlineDark' | 'outlineLight';
    id?: string | undefined;
    children: string;
}

const CtaLink: React.FC<Props> = ({
    href,
    target,
    color,
    hoverBorderColor,
    hoverFontColor,
    hoverBgColor,
    visualStyle = 'outlineDark',
    children,
    id = undefined,
}) => {
    const PageMetadata = useContext(PageMetadataContext);
    const preferredLanguage = PageMetadata.preferredLanguage?.toLowerCase();

    const inlineStyle: React.CSSProperties = {};
    const hoverBorderColorHex = hoverBorderColor || '#f06623';
    const hoverFontColorHex = hoverFontColor || '#ffffff';
    const hoverBgColorHex = hoverBgColor || '#f06623';

    if (color) {
        inlineStyle.color = color;
        inlineStyle.borderColor = color;
    }

    let languageSpecificHref =
        !preferredLanguage ||
        defaultDomainCountryCodes.indexOf(preferredLanguage.toLowerCase()) >
            -1 ||
        href?.includes(preferredLanguage) ||
        href?.includes('//')
            ? href
            : `/${preferredLanguage}${href}`;

    if (languageSpecificHref !== undefined) {
        const [firstCountryValue, secondCountryValue] = languageSpecificHref
            .split('/')
            .filter((item) => item);
        languageSpecificHref =
            countriesList.indexOf(firstCountryValue) > -1 &&
            countriesList.indexOf(secondCountryValue) > -1
                ? href
                : languageSpecificHref;
    }

    return (
        <>
            {color && (
                <style>{`.${styles.ctaLink}:hover { 
                    border-color: ${hoverBorderColorHex} !important; 
                    color: ${hoverFontColorHex} !important;
                    background-color: ${hoverBgColorHex} !important;
                }`}</style>
            )}
            <a
                rel={languageSpecificHref?.endsWith('.pdf') ? 'nofollow' : ''}
                id={id ? id : undefined}
                href={languageSpecificHref}
                target={target}
                className={`${styles.ctaLink} ${styles[visualStyle]}`}
                style={inlineStyle}
            >
                {children}
            </a>
        </>
    );
};

export default CtaLink;
